@mixin navbar-theme($theme) {
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);

    ceto-navbar {
        background: transparent;

        .navbar-item {
            color: #1e2630;

            &.active {
                color: mat-color($accent);
                font-weight: 700;

                &:after {
                    background: mat-color($accent);
                }
            }

            &:hover,
            &:focus {
                color: mat-color($accent);
            }
        }

        .hamburger {
            color: #1e2630;
        }

        .overlay {
            background-color: #FAFAFA;
            color: #c1ccd9;

            a {
                color: #1e2630 !important;
                margin: 0 !important;

                &.active {
                    color: mat-color($accent) !important;
                }
            }

            .overlay a:hover, .overlay a:focus {
                color: mat-color($accent);
            }
        }
    }
}
