// -----------------------------------------------------------------------------------------------------
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$white: (
    500: white,
    contrast: (
        500: $dark-primary-text
    )
);

$black: (
    500: black,
    contrast: (
        500: $light-primary-text,
    )
);

$navy: (
    50: #ECECEE,
    100: #C5C6CB,
    200: #9EA1A9,
    300: #7D818C,
    400: #5C616F,
    500: #3C4252,
    600: #353A48,
    700: #2D323E,
    800: #262933,
    900: #1E2129,
    A100: #C5C6CB,
    A200: #9EA1A9,
    A400: #5C616F,
    A700: #2D323E,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
    'white': $white,
    'black': $black,
    'navy': $navy
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat-typography-config(
    $font-family: 'Muli, Helvetica Neue, Arial, sans-serif',
    $title: mat-typography-level(20px, 32px, 600),
    $body-2: mat-typography-level(14px, 24px, 600),
    $button: mat-typography-level(14px, 14px, 600),
    $input: mat-typography-level(16px, 1.125, 400) // line-height must be unitless !!!
);

// Setup the typography
@include angular-material-typography($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for theme
@import "src/app/themes/form-control.theme";
@import "src/app/components/navbar/navbar.theme";
@import "src/app/components/footer/footer.theme";

// Define a mixin for easier access
@mixin app-theme($theme) {

    // Layout components
    @include form-control-theme($theme);
    @include navbar-theme($theme);
    @include footer-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat-palette($navy);
$default-accent-palette: mat-palette($mat-light-green, 500, 400, 700);
$default-warn-palette: mat-palette($mat-red);

// Create the Material theme object
$theme: mat-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {

    // Create an Angular Material theme from the $theme map
    @include angular-material-theme($theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);

    // Apply the theme to the user components
    @include app-theme($theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette
    );

    @include fuse-color-classes($palettes);

    font-family: Muli, sans-serif !important;
    color: #313943 !important;
    font-weight: 500;
    font-size: 18px;
    overflow-x: hidden;
    overflow-y: auto !important;

    h1 {
        font-family: Muli, sans-serif !important;
        font-size: 32px;
        font-weight: bold;
    }

    h2 {
        font-family: Muli, sans-serif !important;
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
    }

    h3 {
        font-family: Muli, sans-serif !important;
        font-size: 18px;
        font-weight: bold;
    }

    h4 {
        font-family: Muli, sans-serif !important;
        font-size: 14px;
        font-weight: bold;
    }

    h5 {
        font-family: Muli, sans-serif !important;
        font-size: 13px;
        font-weight: bold;
    }

    p, a {
        font-family: Muli, sans-serif !important;
        font-weight: 500;
        font-style: normal;
    }

    mat-label {
        font-family: Muli, sans-serif !important;
        font-weight: 600 !important;
        color: mat-color($default-primary-palette);
    }

    .mat-flat-button {
        border-radius: 12px;
        padding: 4px 14px;
    }

    .emphasis {
        color: mat-color($default-accent-palette, 500);
    }

    .headline {
        color: #f08b44;
    }
}
